import { themes } from 'kb-shared';
import styled from 'styled-components';

export const IconContainer = styled.div<{
  $width?: number;
  $height?: number;
  $color?: string;
  $noMargin?: boolean;
  $iconWidth?: number;
  $iconHeight?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => ($width ? `${$width}px` : '48px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '72px')};
  margin-bottom: ${({ $noMargin }) => ($noMargin ? '0' : '8px')};

  svg {
    width: ${({ $iconWidth }) => ($iconWidth ? `${$iconWidth}px` : '48px')};
    height: ${({ $iconHeight }) => ($iconHeight ? `${$iconHeight}px` : '48px')};
    color: ${({ $color }) => ($color ? $color : themes.colors.yellow.primary)};
  }
`;

export const TextRow = styled.div`
  margin-bottom: 16px;
`;

export const WidgetContainer = styled.div<{ $variant?: number }>`
  position: relative;
  padding: 16px 16px 24px 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    border-radius: 16px;
    padding: 16px 24px 24px 24px;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 24px 32px 32px 32px;
  }
`;

export const BaseWidgetContainer = styled(WidgetContainer)<{ $bgColor?: string }>`
  height: 100%;
  background-color: ${({ $bgColor }) => $bgColor ?? themes.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.card.level0};
`;

export const IllustratedBackgroundWidget = styled(BaseWidgetContainer)`
  ${({ $variant }) =>
    !$variant &&
    `
    background-image: url("data:image/svg+xml,%3Csvg width='178' height='158' viewBox='0 0 178 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EBackground part of circle illustration%3C/title%3E%3Cpath opacity='0.24' fill-rule='evenodd' clip-rule='evenodd' d='M6.4211 118.052L0.333008 127.907L43.4367 158L50.5399 146.549C87.2416 87.3656 142.369 53.4267 201.791 53.4267C261.379 53.4267 316.513 87.2073 353.05 146.101L360.051 157.384L403.333 128.034L397.163 118.052C363.865 64.1945 302.932 -1.16503e-05 201.791 -1.28564e-05C100.644 -1.40626e-05 39.7183 64.1945 6.42126 118.052L6.4211 118.052Z' fill='%23F9E168'/%3E%3C/svg%3E");
    background-position: top 40px right;
  `}

  ${({ $variant }) =>
    $variant === 1 &&
    `
    background-image: url("data:image/svg+xml,%3Csvg width='291' height='158' viewBox='0 0 291 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EYellow half circle%3C/title%3E%3Cpath opacity='0.24' fill-rule='evenodd' clip-rule='evenodd' d='M397.245 118.052L403.333 127.907L360.229 158L353.126 146.549C316.424 87.3656 261.297 53.4267 201.875 53.4267C142.287 53.4267 87.1528 87.2074 50.616 146.101L43.6155 157.384L0.333007 128.034L6.50311 118.052C39.8013 64.1946 100.734 -2.03176e-06 201.875 -6.4528e-06C303.022 -1.0874e-05 363.948 64.1945 397.245 118.052L397.245 118.052Z' fill='%23F9E168'/%3E%3C/svg%3E%0A");
    background-position: top 490px right;
  `}

  ${({ $variant }) =>
    $variant === 2 &&
    `
    background-image: url("data:image/svg+xml,%3Csvg width='291' height='158' viewBox='0 0 291 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EYellow half circle%3C/title%3E%3Cpath opacity='0.24' fill-rule='evenodd' clip-rule='evenodd' d='M396.912 118.052L403 127.907L359.896 158L352.793 146.549C316.091 87.3656 260.964 53.4267 201.542 53.4267C141.954 53.4267 86.8198 87.2074 50.283 146.101L43.2825 157.384L-1.30986e-06 128.034L6.1701 118.052C39.4683 64.1946 100.401 -2.03176e-06 201.542 -6.4528e-06C302.689 -1.0874e-05 363.615 64.1945 396.912 118.052L396.912 118.052Z' fill='%23F9E168'/%3E%3C/svg%3E ");
    background-position: top 88px right;
  `}

  ${({ $variant }) =>
    $variant === 3 &&
    `
    background-image: url("data:image/svg+xml,%3Csvg width='332' height='158' viewBox='0 0 332 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EYellow half circle%3C/title%3E%3Cpath opacity='0.24' fill-rule='evenodd' clip-rule='evenodd' d='M-65.5789 39.9482L-71.667 30.0934L-28.5633 -5.14007e-07L-21.4601 11.4513C15.2416 70.6344 70.3694 104.573 129.791 104.573C189.379 104.573 244.513 70.7927 281.05 11.8991L288.051 0.615796L331.333 29.9661L325.163 39.9484C291.865 93.8055 230.932 158 129.791 158C28.6442 158 -32.2817 93.8055 -65.5787 39.9484L-65.5789 39.9482Z' fill='%23F9E168'/%3E%3C/svg%3E ");
    background-position: top 60px left;
  `}

  ${({ $variant }) =>
    $variant === 4 &&
    `
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjI0MCIgdmlld0JveD0iMCAwIDI0MCAyNDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJLaW5kYm9keSBMb2dvIiBvcGFjaXR5PSIwLjI0Ij4KPHBhdGggaWQ9IlVuaW9uIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE1OC44NDggMzQuMjg1N0wxNjMuMDM3IDM2Ljg3NTVMMTYzLjAzNyAzNi44NzU1QzE4NS45MyA1MS4wMzk0IDIxMy4yMTcgNzYuOTU2MiAyMTMuMjE3IDExOS45ODJDMjEzLjIxNyAxNjMuMDA2IDE4NS45MyAxODguOTI1IDE2My4wMzcgMjAzLjA5TDE1OC43OTQgMjA1LjcxNEwxNDYuMzE4IDE4Ny4zMDNMMTUxLjExNCAxODQuMzI1QzE3Ni4xNDggMTY4Ljc4MyAxOTAuNTA3IDE0NS4zMyAxOTAuNTA3IDExOS45ODJDMTkwLjUwNyA5NC43MDUzIDE3Ni4wOCA3MS4yNTUgMTUwLjkyMyA1NS42NDI4TDE0Ni4wNTYgNTIuNjIxMkwxNTguODQ4IDM0LjI4NTdaTTk0LjY2NzQgNTIuNjIxM0w4OS43OTczIDU1LjY0MjdDNjQuNjM5NyA3MS4yNTQ5IDUwLjIxIDk0LjcwNzggNTAuMjEgMTE5Ljk4MkM1MC4yMSAxNDUuMzMgNjQuNTY5MSAxNjguNzc5IDg5LjYwNjIgMTg0LjMyMkw5NC40MTA2IDE4Ny4yOTlMODEuOTI5OCAyMDUuNzE0TDc3LjY4OTEgMjAzLjA5MkM1NC43OTM3IDE4OC45MjUgMjcuNTA0MiAxNjMuMDA5IDI3LjUwNDIgMTE5Ljk4MkMyNy41MDQyIDc2Ljk1NjIgNTQuNzkxMiA1MS4wMzk1IDc3LjY4MzMgMzYuODc1NUw4MS44NzIzIDM0LjI4NThMOTQuNjY3NCA1Mi42MjEzWiIgZmlsbD0iI0Y5RTE2OCIvPgo8L2c+Cjwvc3ZnPgo=');
    background-position: top -10px right;
  `}

  ${({ $variant }) =>
    $variant === 5 &&
    `
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzE2IiBoZWlnaHQ9IjE1OCIgdmlld0JveD0iMCAwIDMxNiAxNTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuMjQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMzA5LjU3OSAzOS45NDgyTDMxNS42NjcgMzAuMDkzNEwyNzIuNTYzIC0xLjg4NDEyZS0wNkwyNjUuNDYgMTEuNDUxM0MyMjguNzU4IDcwLjYzNDQgMTczLjYzMSAxMDQuNTczIDExNC4yMDkgMTA0LjU3M0M1NC42MjA2IDEwNC41NzMgLTAuNTEzMjQ4IDcwLjc5MjYgLTM3LjA1IDExLjg5OTFMLTQ0LjA1MDUgMC42MTU3ODRMLTg3LjMzMyAyOS45NjYxTC04MS4xNjI5IDM5Ljk0ODRDLTQ3Ljg2NDggOTMuODA1NCAxMy4wNjc5IDE1OCAxMTQuMjA5IDE1OEMyMTUuMzU2IDE1OCAyNzYuMjgyIDkzLjgwNTUgMzA5LjU3OSAzOS45NDg0TDMwOS41NzkgMzkuOTQ4MloiIGZpbGw9IiM0MzQ3OTIiLz4KPC9zdmc+Cg==');
      background-position: top 32px left 0;
    `}

  ${({ $variant }) =>
    $variant === 6 &&
    `
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjY3IiBoZWlnaHQ9IjE1OCIgdmlld0JveD0iMCAwIDI2NyAxNTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuMjQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNLTEyOS45MTIgMzkuOTQ4MkwtMTM2IDMwLjA5MzRMLTkyLjg5NjMgLTUuMTQwMDdlLTA3TC04NS43OTMxIDExLjQ1MTNDLTQ5LjA5MTQgNzAuNjM0NCA2LjAzNjQ0IDEwNC41NzMgNjUuNDU3NSAxMDQuNTczQzEyNS4wNDYgMTA0LjU3MyAxODAuMTggNzAuNzkyNyAyMTYuNzE3IDExLjg5OTFMMjIzLjcxNyAwLjYxNTc5NkwyNjcgMjkuOTY2MUwyNjAuODMgMzkuOTQ4NEMyMjcuNTMyIDkzLjgwNTUgMTY2LjU5OSAxNTggNjUuNDU3NSAxNThDLTM1LjY4ODggMTU4IC05Ni42MTQ3IDkzLjgwNTUgLTEyOS45MTIgMzkuOTQ4NEwtMTI5LjkxMiAzOS45NDgyWiIgZmlsbD0iI0YwQzhCRiIvPgo8L3N2Zz4K');
      background-position: top 48px left;
    `}
  
  ${({ $variant }) =>
    $variant === 7 &&
    `
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjY2IiBoZWlnaHQ9IjE0MiIgdmlld0JveD0iMCAwIDI2NiAxNDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuMjQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNi4wODgwOSAxMTguMDUyTDMuNTg4NmUtMDcgMTI3LjkwN0w0My4xMDM3IDE1OEw1MC4yMDY5IDE0Ni41NDlDODYuOTA4NiA4Ny4zNjU2IDE0Mi4wMzYgNTMuNDI2NyAyMDEuNDU4IDUzLjQyNjdDMjYxLjA0NiA1My40MjY3IDMxNi4xOCA4Ny4yMDczIDM1Mi43MTcgMTQ2LjEwMUwzNTkuNzE3IDE1Ny4zODRMNDAzIDEyOC4wMzRMMzk2LjgzIDExOC4wNTJDMzYzLjUzMiA2NC4xOTQ1IDMwMi41OTkgLTEuMTY1MDNlLTA1IDIwMS40NTggLTEuMjg1NjRlLTA1QzEwMC4zMTEgLTEuNDA2MjZlLTA1IDM5LjM4NTMgNjQuMTk0NSA2LjA4ODI1IDExOC4wNTJMNi4wODgwOSAxMTguMDUyWiIgZmlsbD0iI0YwQzhCRiIvPgo8L3N2Zz4K);
    background-position: bottom 10px right 0px;
  `}

${({ $variant }) =>
  $variant === 8 &&
  `
    background-image: url("data:image/svg+xml,%3Csvg width='158' height='356' viewBox='0 0 158 356' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EHalf circle%3C/title%3E%3Cpath opacity='0.24' fill-rule='evenodd' clip-rule='evenodd' d='M39.9482 396.912L30.0934 403L0 359.896L11.4513 352.793C70.6344 316.091 104.573 260.964 104.573 201.542C104.573 141.954 70.7927 86.8198 11.8991 50.283L0.6158 43.2825L29.9661 0L39.9484 6.1701C93.8055 39.4683 158 100.401 158 201.542C158 302.689 93.8055 363.615 39.9484 396.912L39.9482 396.912Z' fill='%23F9E168'/%3E%3C/svg%3E%0A");
    background-position: bottom 0 right 15px;
  `}

${({ $variant }) =>
  $variant === 9 &&
  `
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTciIGhlaWdodD0iNDAzIiB2aWV3Qm94PSIwIDAgOTcgNDAzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBvcGFjaXR5PSIwLjI0IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExOC41NTIgMzk2LjkxMkwxMjguNDA3IDQwM0wxNTguNSAzNTkuODk2TDE0Ny4wNDkgMzUyLjc5M0M4Ny44NjU2IDMxNi4wOTEgNTMuOTI2NyAyNjAuOTY0IDUzLjkyNjcgMjAxLjU0MkM1My45MjY3IDE0MS45NTQgODcuNzA3MyA4Ni44MTk4IDE0Ni42MDEgNTAuMjgzTDE1Ny44ODQgNDMuMjgyNUwxMjguNTM0IDBMMTE4LjU1MiA2LjE3MDFDNjQuNjk0NSAzOS40NjgzIDAuNDk5OTg1IDEwMC40MDEgMC40OTk5ODUgMjAxLjU0MkMwLjQ5OTk4NSAzMDIuNjg5IDY0LjY5NDUgMzYzLjYxNSAxMTguNTUyIDM5Ni45MTJMMTE4LjU1MiAzOTYuOTEyWiIgZmlsbD0iI0Y5RTE2OCIvPgo8L3N2Zz4K');
    background-position: top 50% right;
  `}

${({ $variant }) =>
  $variant === 10 &&
  `
    background-position: bottom 0 left 50%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQxIiBoZWlnaHQ9IjM0MCIgdmlld0JveD0iMCAwIDM0MSAzNDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIG9wYWNpdHk9IjAuMjQiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIyNS4yMzEgNDguNTcxM0wyMzEuMTY2IDUyLjI0MDFMMjMxLjE2NiA1Mi4yNDAyQzI2My41OTcgNzIuMzA1OCAzMDIuMjU0IDEwOS4wMjEgMzAyLjI1NCAxNjkuOTc0QzMwMi4yNTQgMjMwLjkyNCAyNjMuNTk3IDI2Ny42NDQgMjMxLjE2NiAyODcuNzFMMjI1LjE1NCAyOTEuNDI4TDIwNy40OCAyNjUuMzQ1TDIxNC4yNzUgMjYxLjEyN0MyNDkuNzM5IDIzOS4xMDkgMjcwLjA4MSAyMDUuODg0IDI3MC4wODEgMTY5Ljk3NEMyNzAuMDgxIDEzNC4xNjYgMjQ5LjY0NCAxMDAuOTQ0IDIxNC4wMDUgNzguODI3MkwyMDcuMTEgNzQuNTQ2NkwyMjUuMjMxIDQ4LjU3MTNaTTEzNC4zMDcgNzQuNTQ2NkwxMjcuNDA3IDc4LjgyN0M5MS43NjczIDEwMC45NDQgNzEuMzI1MyAxMzQuMTY5IDcxLjMyNTMgMTY5Ljk3NEM3MS4zMjUzIDIwNS44ODQgOTEuNjY3NCAyMzkuMTA0IDEyNy4xMzcgMjYxLjEyMkwxMzMuOTQzIDI2NS4zNDFMMTE2LjI2MiAyOTEuNDI4TDExMC4yNTQgMjg3LjcxNEM3Ny44MTg4IDI2Ny42NDQgMzkuMTU4NyAyMzAuOTI5IDM5LjE1ODcgMTY5Ljk3NEMzOS4xNTg3IDEwOS4wMjEgNzcuODE1MyA3Mi4zMDU4IDExMC4yNDYgNTIuMjQwMUwxMTYuMTggNDguNTcxM0wxMzQuMzA3IDc0LjU0NjZaIiBmaWxsPSIjRjBDOEJGIi8+CjwvZz4KPC9zdmc+Cg==');
  `}

${({ $variant }) =>
  $variant === 11 &&
  `
    background-position: bottom 50% left 10px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU4IiBoZWlnaHQ9IjQwMyIgdmlld0JveD0iMCAwIDE1OCA0MDMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuMjQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMzkuOTQ4MiA2LjA4ODA5TDMwLjA5MzQgMEwwIDQzLjEwMzdMMTEuNDUxMyA1MC4yMDY5QzcwLjYzNDQgODYuOTA4NiAxMDQuNTczIDE0Mi4wMzYgMTA0LjU3MyAyMDEuNDU4QzEwNC41NzMgMjYxLjA0NiA3MC43OTI3IDMxNi4xOCAxMS44OTkxIDM1Mi43MTdMMC42MTU4IDM1OS43MTdMMjkuOTY2MSA0MDNMMzkuOTQ4NCAzOTYuODNDOTMuODA1NSAzNjMuNTMyIDE1OCAzMDIuNTk5IDE1OCAyMDEuNDU4QzE1OCAxMDAuMzExIDkzLjgwNTUgMzkuMzg1MyAzOS45NDg0IDYuMDg4MjVMMzkuOTQ4MiA2LjA4ODA5WiIgZmlsbD0iI0YwQzhCRiIvPgo8L3N2Zz4K");
  `}

  background-repeat: no-repeat;
`;

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > button {
    margin-bottom: 12px;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-top: 32px;
  }
`;

export const WidgetTitleContainer = styled.div<{ $noMargin?: boolean }>`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;

  ${({ $noMargin }) => ($noMargin ? '' : 'margin-bottom: 16px;')}
`;
