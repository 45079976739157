import React from 'react';

import { Text } from 'components/v2/Typography';
import { DashboardSettings } from 'screens/Dashboard/DashboardSettings/DashboardSettings';

import { InfoAreaContainer } from './NoLMPEntered.styled';
import { PregnancyCalculatorActionButtons } from './PregnancyCalculatorActionButtons';
import { TileWidget } from './TileWidget';

export const NoLMPEntered = ({ onCancelClick }: { onCancelClick?: () => void }) => (
  <TileWidget state="no-lmp-entered">
    <DashboardSettings />

    <InfoAreaContainer>
      <Text fontStyle="regular" size="xs">
        Estimated Due Date <br /> & Gestional Age
      </Text>
      <Text>Not Logged</Text>

      <Text fontStyle="regular" size="sm">
        Your estimated due date and gestational age are based on your last menstrual period. Please
        update the Period Tracker so we can calculate your dates.
      </Text>
    </InfoAreaContainer>

    <PregnancyCalculatorActionButtons onCancelClick={onCancelClick} />
  </TileWidget>
);
