import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import { Button } from 'components/v2/Buttons/Button';
import { TextField, SelectField } from 'components/v2/Form';
import { Checkbox } from 'components/v2/Inputs';
import { Modal } from 'components/v2/Modal/Modal';
import { Heading } from 'components/v2/Typography/Heading';
import { assureProperCentRounding } from 'utilities';
import { analytics } from 'utilities/analytics';
import { USDollar } from 'utilities/currencyFormat';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';

import useCreatePayment from '../../hooks/use-create-payment';
import { CreditCardDetails } from '../InvoicePayModal/CreditCardDetails';
import * as Styled from '../InvoicePayModal/InvoicePayModal.styled';
import { PayCycleResponsiveContainer } from './PayCycleModal.styled';

export const PayCycleModal = ({
  open,
  onClose,
  onPaid
}: {
  open: boolean;
  onClose: () => void;
  onPaid: () => void;
}) => {
  const [loading, setloading] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [kbStripeCardStripeIdentifierSelected, setKbStripeCardStripeIdentifier] = useState('');
  const {
    createPaymentWithCreditCardNumber,
    createPaymentWithStripeIdentifier
  } = useCreatePayment();
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);

  useEffect(() => {
    setFormData(INITIAL_FORM_DATA);
    setStripeContext(undefined);

    if (open) {
      analytics.track(analytics.EVENTS.PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_LOADED);
    }
  }, [open]);

  const onSuccessPayment = () => {
    const price = USDollar.format(parseFloat(formData.amount));
    showSuccessToast(
      `You've successfully made your ${price} 
        payment! Please allow 5-7 business days for the total balance to reflect the payment just made.`
    );

    setloading(false);
    onPaid();
  };

  const onErrorPayment = (errorMessage: String) => {
    showErrorToast(errorMessage || DEFAULT_ERROR_MESSAGE);
    setloading(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setloading(true);

    if (stripeContext) {
      const params = {
        stripe: stripeContext.stripe,
        cardNumberElement: stripeContext.cardNumberElement,
        amountCents: parseFloat(formData.amount) * 100,
        reason: formData.reason?.value,
        reasonDetails: formData.reasonOther
      };

      createPaymentWithCreditCardNumber(params, onSuccessPayment, onErrorPayment);
    }

    if (kbStripeCardStripeIdentifierSelected) {
      const params = {
        kbStripeCardStripeIdentifier: kbStripeCardStripeIdentifierSelected,
        amountCents: parseFloat(formData.amount) * 100,
        reason: formData.reason?.value,
        reasonDetails: formData.reasonOther
      };

      createPaymentWithStripeIdentifier(params, onSuccessPayment, onErrorPayment);
    }

    analytics.track(analytics.EVENTS.PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_SUCCESSFUL);
  };

  const updateFormData = (field: string, event: any) => {
    let value;

    if (field === 'reason') {
      value = event;
    } else {
      value = event.currentTarget.value;
    }

    if (field === 'amount' && !Number.isNaN(parseFloat(value))) {
      if (value < 0) {
        value = undefined;
      } else {
        value = assureProperCentRounding(value);
      }
    }

    setFormData({ ...formData, [field]: value });
  };

  const toggleConfirmCheckbox = () => {
    setFormData({ ...formData, confirmed: !formData.confirmed });
  };

  const submitButtonIsDisabled =
    loading ||
    !formData.confirmed ||
    !formData.amount ||
    !inputValid ||
    (!stripeContext && kbStripeCardStripeIdentifierSelected === '');
  return (
    <Modal title="Make a payment" open={open} onCancel={onClose} maxWidth={720}>
      <Styled.ResponsiveForm onSubmit={onSubmit}>
        <PayCycleResponsiveContainer>
          <Styled.InputWrapper>
            <TextField
              id="amount-input"
              label="Amount"
              value={formData.amount}
              placeholder="Enter amount..."
              type="number"
              max={10000}
              step={0.01}
              spellCheck={false}
              onChange={event => updateFormData('amount', event)}
              fullWidth
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <SelectField
              label="REASON"
              placeholder="Select"
              selected={formData.reason}
              onChange={event => updateFormData('reason', event)}
              options={REASON_OPTIONS}
              id="reason-select-id"
            />
          </Styled.InputWrapper>
        </PayCycleResponsiveContainer>

        <Styled.PaymentSection>
          <Heading tag="div" styledAs="h5">
            Payment Details
          </Heading>

          <CreditCardDetails
            onSelectCreditCard={data => {
              setKbStripeCardStripeIdentifier(data);
            }}
            onStripeCardElementInitialized={(
              stripe: Stripe,
              cardNumberElement: StripeCardNumberElement
            ) => {
              setStripeContext({
                stripe,
                cardNumberElement
              });
            }}
            onStripeElementUnmount={() => setStripeContext(undefined)}
            onValidation={valid => setInputValid(valid)}
          />
        </Styled.PaymentSection>

        <Styled.PayCycleCheckbox>
          <Checkbox
            label="I understand that any credit resulting from this payment may be applied to a past due balance."
            isChecked={formData.confirmed}
            onChange={() => toggleConfirmCheckbox()}
            id="checkbox-confirm-understanding"
          />
        </Styled.PayCycleCheckbox>

        <Styled.InvoiceButtons>
          <Button label="Cancel" type="button" category="secondary" size="lg" onClick={onClose} />
          <Button label="Pay" category="primary" isDisabled={submitButtonIsDisabled} size="lg" />
        </Styled.InvoiceButtons>
      </Styled.ResponsiveForm>
    </Modal>
  );
};

const REASON_OPTIONS = [
  { label: 'Cycle Payment', value: 'cycle_payment' },
  { label: 'Kindbody Benefit Payment', value: 'employer patient payment' },
  { label: 'Kindbody Rx (non-employer)', value: 'Kindbody Rx (non-employer)' }
];

interface FormDataValues {
  amount: string;
  reason: typeof REASON_OPTIONS[number];
  reasonOther: string;
  confirmed: boolean;
}

const INITIAL_FORM_DATA: FormDataValues = {
  amount: '',
  reason: REASON_OPTIONS[0],
  reasonOther: '',
  confirmed: false
};

const DEFAULT_ERROR_MESSAGE =
  "We're sorry but we could not process your payment. Please try again.";
