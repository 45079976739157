import { CustomTile } from '../CustomWidgets/CustomWidgets.types';
import { DashboardWidgetId, DashboardWidgetList } from './DashboardSettings.types';

export function isHiddenWidget(
  hiddenWidgets: DashboardWidgetList | undefined,
  widgetId: DashboardWidgetId
): boolean {
  return Boolean(hiddenWidgets?.includes(widgetId));
}

export function compareHiddenDashboardWidgetSettings(
  a: DashboardWidgetList,
  b: DashboardWidgetList
): boolean {
  const aSet = new Set(a);
  const bSet = new Set(b);

  if (aSet.size !== bSet.size) return false;

  for (const id of aSet) {
    if (!bSet.has(id)) return false;
  }

  return true;
}

export function getCustomTileId(tile: CustomTile): string {
  return `custom-tile-${tile.id}`;
}
