import { createIcon } from './Icons.utils';

export const ChevronLeft = createIcon('chevronLeft');
export const ChevronRight = createIcon('chevronRight');
export const CheckMark = createIcon('check');
export const XMark = createIcon('xMark');
export const ChevronDown = createIcon('chevronDown');
export const ChevronUp = createIcon('chevronUp');
export const Eye = createIcon('eye');
export const EyeSlash = createIcon('eyeSlash');
export const Pen = createIcon('pen');
export const MagnifyingGlass = createIcon('magnifyingGlass');
export const Headset = createIcon('headset');
export const UserGear = createIcon('userGear');
export const HandsHoldingHeart = createIcon('handsHoldingHeart');
export const HandHoldingHeart = createIcon('handHoldingHeart');
export const MoneyCheckDollar = createIcon('moneyCheckDollar');
export const StaffSnake = createIcon('staffSnake');
export const Comments = createIcon('comments');
export const Grid = createIcon('grid');
export const CalendarCheck = createIcon('calendarCheck');
export const MessageLines = createIcon('messageLines');
export const SquarePollVertical = createIcon('squarePollVertical');
export const BookUser = createIcon('bookUser');
export const FileArrowUp = createIcon('fileArrowUp');
export const FileInvoiceDollar = createIcon('fileInvoiceDollar');
export const FileHeart = createIcon('fileHeart');
export const ClipBoardMedical = createIcon('clipBoardMedical');
export const ShieldHeart = createIcon('shieldHeart');
export const MessagePlus = createIcon('messagePlus');
export const Plus = createIcon('plus');
export const Ellipsis = createIcon('ellipsis');
export const Amex = createIcon('ccAmex');
export const CreditCard = createIcon('creditCard');
export const DinersClub = createIcon('ccDinersClub');
export const Discover = createIcon('ccDiscover');
export const Jcb = createIcon('ccJcb');
export const MasterCard = createIcon('ccMastercard');
export const Visa = createIcon('ccVisa');
export const Apple = createIcon('apple');
export const Paperclip = createIcon('paperclip');
export const FileLines = createIcon('fileLines');
export const FilePdf = createIcon('filePdf');
export const Calendar = createIcon('calendar');
export const Envelope = createIcon('envelope');
export const PaperPlane = createIcon('paperPlane');
export const PeopleArrows = createIcon('peopleArrows');
export const CircleExclamation = createIcon('circleExclamation');
export const CheckCircle = createIcon('checkCircle');
export const CircleInfo = createIcon('circleInfo');
export const Warning = createIcon('warning');
export const ArrowRightFromBracket = createIcon('arrowRightFromBracket');
export const CaretDown = createIcon('caretDown');
export const CaretUp = createIcon('caretUp');
export const Trash = createIcon('trash');
export const CalendarPlus = createIcon('calendarPlus');
export const Film = createIcon('film');
export const CakeCandles = createIcon('cakeCandles');
export const Prescription = createIcon('prescription');
export const Phone = createIcon('phone');
export const House = createIcon('house');
export const ClipboardUser = createIcon('clipboardUser');
export const HeartPulse = createIcon('heartPulse');
export const Pills = createIcon('pills');
export const CalendarHeart = createIcon('calendarHeart');
export const Route = createIcon('route');
export const CircleDot = createIcon('circleDot');
export const PersonBreastfeeding = createIcon('personBreastfeeding');
export const Whatsapp = createIcon('whatsapp');
export const MessageSms = createIcon('messageSms');
export const PhoneOffice = createIcon('phoneOffice');
export const ArrowLeft = createIcon('arrowLeft');
export const ArrowRight = createIcon('arrowRight');
export const EmployerBenefits = createIcon('award');
export const Sort = createIcon('sort');
export const Seedling = createIcon('seedling');
export const Droplet = createIcon('droplet');
export const Clock = createIcon('clock');
export const Pin = createIcon('pin');
export const CircleQuestion = createIcon('circleQuestion');
export const Bold = createIcon('bold');
export const Italic = createIcon('italic');
export const Underline = createIcon('underline');
export const Hashtag = createIcon('hashtag');
export const Period = createIcon('period');
export const ListCheck = createIcon('listCheck');
export const Lock = createIcon('lock');
export const Link = createIcon('link');
