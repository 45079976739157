import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { EmployerBenefits } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading, Text } from 'components/v2/Typography';
import { Patient, themes } from 'kb-shared';
import { DashboardSettings } from 'screens/Dashboard/DashboardSettings/DashboardSettings';
import { DashboardWidgetList } from 'screens/Dashboard/DashboardSettings/DashboardSettings.types';
import { isHiddenWidget } from 'screens/Dashboard/DashboardSettings/DashboardSettings.utils';
import {
  BaseWidgetContainer,
  IconContainer,
  TextRow,
  WidgetTitleContainer
} from 'screens/Dashboard/Widgets/Widgets.styled';
import { showActivateBenefitsWidget } from 'screens/Dashboard/Widgets/Widgets.utils';
import { analytics } from 'utilities/analytics';
import { RoutesPath } from 'utilities/pageUrl';

import { ActivateBenefitsContainer } from './ActivateBenefitsWidget.styled';

interface Props {
  patient: Patient;
  hiddenWidgets?: DashboardWidgetList;
}

export const ActivateBenefitsWidget = ({ patient, hiddenWidgets }: Props) => {
  const isHiddenSaved = localStorage.getItem('BenefitsWidgetIsHidden') === 'true';
  const [isHidden, setIsHidden] = useState(isHiddenSaved);
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('BenefitsWidgetIsHidden', isHidden.toString());
  }, [isHidden]);

  if (!showActivateBenefitsWidget(patient) || isHiddenWidget(hiddenWidgets, 'activateBenefits'))
    return null;

  return (
    <BaseWidgetContainer $bgColor={themes.colors.neutral.primaryNavy}>
      <DashboardSettings />

      <IconContainer $iconWidth={36} $iconHeight={48} $color={themes.colors.semantic.pink}>
        <EmployerBenefits type="solid" />
      </IconContainer>
      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2" color={themes.colors.semantic.pink}>
          My Benefit Coverage
        </Heading>
      </WidgetTitleContainer>
      <ActivateBenefitsContainer>
        <TextRow>
          <Text color={themes.colors.white} fontStyle="medium" size="lg">
            Does your employer have the Kindbody benefit?
          </Text>
        </TextRow>
        <Button
          size="lg"
          fullWidth={true}
          label="ACTIVATE YOUR BENEFIT"
          onClick={() => {
            analytics.track(analytics.EVENTS.ACTIVATION_BENEFITS_STARTED);
            history.push(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
          }}
        />
      </ActivateBenefitsContainer>
      <LinkButton
        onClick={() => {
          analytics.track(analytics.EVENTS.EMPLOYER_NOT_OFFERING_BENEFITS);
          setIsHidden(true);
        }}
        text="MY EMPLOYER DOES NOT OFFER KINDBODY BENEFITS"
        size="lg"
        fontStyle="medium"
        color={themes.colors.white}
      />
    </BaseWidgetContainer>
  );
};
