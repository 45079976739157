import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { Loader } from 'components/Loader/Loader';
import { TimeSlot } from 'kb-shared';
import {
  themes,
  graphql as KBGQL,
  CancelAppointmentResponseData,
  RescheduleAppointmentResponseData
} from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { CancellationPolicy } from 'screens/Book/components/CancellationPolicy/CancellationPolicy';

import Modal from '../../components/Modal';
import { BookingCalendarTime } from '../Book/components/BookingCalendarTime/BookingCalendarTime';
import Card from './card';
import CancellationSelection from './components/CancellationSelection';
import { Container, Error, SubTitle } from './ModalCancelResched.styled';
import { DisplayState, Props, Action } from './ModalCancelResched.types';

export const ModalCancelReschedule = ({
  visible,
  onClose,
  appointment,
  onReschedule,
  onAppointmentCancelled
}: Props) => {
  const [display, setDisplay] = useState<DisplayState>('default');
  const [confirmation, setConfirmation] = useState<Action | undefined>();
  const [cancellation, setCancellation] = useState({ reason: '', note: '' });
  const [submitting, setSubmitting] = useState(false);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [reschedule, setReschedule] = useState<{ selectedTimeSlot: TimeSlot | null }>({
    selectedTimeSlot: null
  });
  const [rescheduleAppointment] = useMutation<RescheduleAppointmentResponseData>(
    KBGQL.mutate.RESCHEDULE_APPOINTMENT,
    {
      onError: error => BugTracker.notify(error, 'ModalCancelReschedule')
    }
  );
  const [cancelAppointment] = useMutation<CancelAppointmentResponseData>(
    KBGQL.mutate.CANCEL_APPOINTMENT_W_REASON,
    {
      onError: error => BugTracker.notify(error, 'ModalCancelAppointment')
    }
  );

  const handleClose = () => {
    if (confirmation === 'cancelled' && onAppointmentCancelled) {
      onAppointmentCancelled();
    }

    setShowButtons(false);
    onClose();
  };

  useEffect(() => {
    if (!visible) {
      setDisplay('default');
      setError('');
    }
  }, [visible]);

  const getTitle = () => {
    switch (display) {
      case 'default':
        return 'Modify Appointment';
      case 'reschedule':
        return 'Reschedule Appointment';
      case 'confirm_cancel':
        return 'Cancel Appointment';
      case 'cancelled':
        return 'Your appointment has been canceled.';
      case 'confirmed':
        return 'Confirmation';
      case 'error':
        return 'Sorry, there was a problem...';
      default:
        return '';
    }
  };

  const generateButton = (name: string, onClick?: Function) => {
    let button = {
      text: '',
      color: '',
      onClick: () => {}
    };
    switch (name) {
      case 'cancel':
        button = {
          text: 'Cancel Appointment',
          color: themes.colors.white,
          onClick: () => {
            setDisplay('confirm_cancel');
          }
        };
        break;
      case 'close':
        button = {
          text: 'Close',
          color: themes.colors.white,
          onClick: () => {
            handleClose();
            setError('');
          }
        };
        break;
      case 'reschedule':
        button = {
          text: 'Reschedule Appointment',
          color: themes.colors.yellow.primary,
          onClick: () => {
            setDisplay('reschedule');
          }
        };
        break;
      case 'back':
        button = {
          text: 'Back',
          color: themes.colors.white,
          onClick: () => {
            setDisplay('default');
            setError('');
          }
        };
        break;

      case 'submit':
        button = {
          text: 'Submit',
          color: themes.colors.yellow.primary,
          onClick: () => {}
        };
        break;
    }

    if (button && onClick) {
      button.onClick = submit.bind(this, onClick);
    }

    return button;
  };

  const setTimeSlotSelected = (selectedTimeSlot: TimeSlot | null) => {
    setReschedule({ selectedTimeSlot });
  };

  const setScreenDetails = (parameters: any) => {
    if (display === 'confirm_cancel') setCancellation(parameters);
    else if (display === 'reschedule') setReschedule(parameters);
  };

  const submit = async (mutationFunction: Function) => {
    if (submitting) return;

    if (display === 'confirm_cancel') {
      if (cancellation.reason === 'Other - Specify' && cancellation.note.trim().length === 0) {
        setError('*A reason is required*');
      } else {
        setError('');
        try {
          setSubmitting(true);

          const response = await mutationFunction({
            variables: {
              appointmentId: parseInt(appointment.id),
              ...cancellation
            }
          });

          if (response.data) {
            setDisplay('confirmed');
            setConfirmation('cancelled');
          } else {
            setDisplay('error');
            setError('There was an error trying to cancel this appointment. Please try again.');
          }
        } catch (e) {
          BugTracker.notify(e, 'ModalCancelSubmitConfirmCancel');
        } finally {
          setSubmitting(false);
        }
      }
    }

    if (display === 'reschedule') {
      if (reschedule.selectedTimeSlot) {
        setError('');

        try {
          setSubmitting(true);

          const response = await mutationFunction({
            variables: {
              appointmentId: parseInt(appointment.id),
              newTimeSlotId: parseInt(reschedule.selectedTimeSlot.id)
            }
          });

          if (response.data) {
            setDisplay('confirmed');
            setConfirmation('rescheduled');
          } else {
            setDisplay('error');
            setError('There was an issue trying to reschedule this appointment');
          }
        } catch (e) {
          BugTracker.notify(e, 'ModalCancelSubmitRecheduled');
        } finally {
          setSubmitting(false);
        }
      } else {
        setError('*Please select a time slot*');
      }
    }
  };

  const renderCancellationPolicy = () => {
    const emailBody = appointment
      ? `&body=%0D%0A%0D%0A%0D%0A%0D%0AAppointment # ${appointment.id}%0D%0A${moment(
          appointment.startTime
        ).format('dddd MMMM d, h:mm A z')}`
      : '';

    return (
      <SubTitle>
        <CancellationPolicy
          emailBody={emailBody}
          noCancelNorRescheduleVariant={
            appointment && !appointment.cancelable && !appointment.reschedulable
          }
        />
      </SubTitle>
    );
  };

  const renderRescheduleScreen = () => {
    const { selectedTimeSlot } = reschedule;
    const locationId = appointment.location.id;
    const appointmentType = appointment.appointmentType;
    const appointmentId = parseInt(appointment.id);

    const buttons =
      appointment.reschedulable && showButtons
        ? [generateButton('back'), generateButton('submit', rescheduleAppointment)]
        : [];

    return (
      <Card type="mobile-no-padding" title={getTitle()} onClose={handleClose} buttons={buttons}>
        <SubTitle>
          To reschedule your appointment, choose a date and time from the calendar below:
        </SubTitle>
        <BookingCalendarTime
          locationId={locationId}
          appointmentType={appointmentType}
          selectedTimeSlot={selectedTimeSlot}
          onSelectTimeSlot={setTimeSlotSelected}
          appointmentId={appointmentId}
          selectedLabTimeZone={appointment.timeZone}
          onAppointmentsFound={() => {
            setShowButtons(true);
          }}
        />
        {error && <Error>{error}</Error>}
      </Card>
    );
  };

  const renderCancellationScreen = () => {
    const buttons = appointment.cancelable
      ? [generateButton('back'), generateButton('submit', cancelAppointment)]
      : [];

    return (
      <Card title={getTitle()} onClose={handleClose} buttons={buttons}>
        <SubTitle>
          You have chosen to cancel your appointment <br />
          Please select a reason below:
        </SubTitle>
        <CancellationSelection setCancellationInformation={setScreenDetails} />
        {error && <Error>{error}</Error>}
      </Card>
    );
  };

  const renderConfirmationScreen = () => {
    let button = generateButton('close');
    if (confirmation === 'cancelled' && onAppointmentCancelled) {
      button = generateButton('close');
      button.onClick = () => {
        setError('');
        handleClose();
      };
    } else if ((confirmation === 'cancelled' || confirmation === 'rescheduled') && onReschedule) {
      button = generateButton('close');
      button.onClick = () => {
        onReschedule();
        setError('');
        handleClose();
      };
    }

    if (display === 'error') {
      return (
        <Card title={getTitle()} onClose={handleClose} buttons={[button]}>
          <SubTitle>{error}</SubTitle>
        </Card>
      );
    }

    return (
      <Card title={getTitle()} onClose={handleClose} buttons={[button]}>
        {confirmation === 'cancelled' && <SubTitle>Your appointment has been canceled.</SubTitle>}
        {confirmation === 'rescheduled' && (
          <SubTitle>Your appointment has been rescheduled.</SubTitle>
        )}
      </Card>
    );
  };

  const renderModificationScreen = () => {
    const buttons = (() => {
      if (appointment.reschedulable && appointment.cancelable) {
        return [generateButton('cancel'), generateButton('reschedule')];
      }

      if (appointment.cancelable) {
        return [generateButton('cancel')];
      }

      if (appointment.reschedulable) {
        return [generateButton('reschedule')];
      }

      return [];
    })();

    return (
      <Card title={getTitle()} onClose={handleClose} buttons={buttons}>
        {renderCancellationPolicy()}
      </Card>
    );
  };

  const handleConfirmation = () => {
    if (confirmation === 'cancelled' && onAppointmentCancelled) {
      onAppointmentCancelled();
    } else if ((confirmation === 'cancelled' || confirmation === 'rescheduled') && onReschedule) {
      onReschedule();
    }
  };

  const renderSubmittingCard = () => {
    let title = '';

    if (display === 'confirm_cancel') {
      title = 'Cancelling Appointment';
    } else if (display === 'reschedule') {
      title = 'Rescheduling Appointment';
    } else {
      title = 'Submitting';
    }

    return (
      <Card title={title} onClose={handleClose} buttons={[]}>
        <Loader container />
      </Card>
    );
  };

  return (
    <Modal
      onRequestClose={() => {
        handleConfirmation();
        onClose();
      }}
      open={visible}
    >
      {submitting && renderSubmittingCard()}

      {!submitting && (
        <Container>
          {display === 'confirm_cancel' && renderCancellationScreen()}
          {display === 'reschedule' && renderRescheduleScreen()}
          {display === 'default' && renderModificationScreen()}
          {(display === 'confirmed' || display === 'error') && renderConfirmationScreen()}
        </Container>
      )}
    </Modal>
  );
};
