import React from 'react';
import { useHistory } from 'react-router-dom';

import { CheckCircle, CircleDot, Route } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Heading, Text } from 'components/v2/Typography';
import { buildCompactJourney } from 'screens/MyJourney/journeySteps';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';

import { JourneyResponse } from '../Dashboard.types';
import { DashboardSettings } from '../DashboardSettings/DashboardSettings';
import { DashboardWidgetList } from '../DashboardSettings/DashboardSettings.types';
import { isHiddenWidget } from '../DashboardSettings/DashboardSettings.utils';
import {
  BaseWidgetContainer,
  ButtonsContainer,
  IconContainer,
  TextRow,
  WidgetTitleContainer
} from '../Widgets/Widgets.styled';
import { showJourneyWidget } from '../Widgets/Widgets.utils';
import {
  SingleStepContainer,
  SingleStepIllustrationContainer,
  StepsContainer
} from './JourneyWidget.styled';

interface Props {
  journeyData: JourneyResponse | undefined;
  hiddenWidgets?: DashboardWidgetList;
}

export const JourneyWidget = ({ journeyData, hiddenWidgets }: Props) => {
  const history = useHistory();

  if (!showJourneyWidget(journeyData) || isHiddenWidget(hiddenWidgets, 'myJourney')) return null;

  // just for Ts to know that journeyData is not undefined
  if (!journeyData) return null;

  const { cyclePlan, patientTodos, results, appointments, journeyType } = journeyData;

  const journey = buildCompactJourney({
    appointments,
    patientTodos,
    results,
    triggerInstruction: null,
    journeyType,
    cyclePlan
  });

  if (!journey) return null;

  return (
    <BaseWidgetContainer>
      <DashboardSettings />

      <IconContainer $iconWidth={48} $iconHeight={48}>
        <Route type="solid" />
      </IconContainer>
      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2">
          My Journey
        </Heading>
      </WidgetTitleContainer>
      <TextRow>
        <Text fontStyle="semibold">
          Track your progress and keep abreast of what&apos;s to come in your journey.
        </Text>
      </TextRow>
      <StepsContainer>
        {journey.steps.map((step, index) => {
          const completed = step.isComplete;
          const last = index === journey.steps.length - 1;
          return (
            <SingleStepContainer key={step.title} $last={last}>
              <SingleStepIllustrationContainer $completed={completed} $last={last}>
                {completed ? <CheckCircle type="solid" /> : <CircleDot type="solid" />}
              </SingleStepIllustrationContainer>
              <Text fontStyle={completed ? 'bold' : 'regular'} size="lg">
                {step.title}
              </Text>
            </SingleStepContainer>
          );
        })}
      </StepsContainer>

      <ButtonsContainer>
        <Button
          label="View more"
          size="lg"
          category="primary-dark"
          onClick={() => {
            analytics.track(analytics.EVENTS.MY_JOURNEY_VIEW_MORE);
            history.push(pageUrl.myJourney());
          }}
        />
      </ButtonsContainer>
    </BaseWidgetContainer>
  );
};
