import React from 'react';

import { Heading, Text } from 'components/v2/Typography';
import { DashboardSettings } from 'screens/Dashboard/DashboardSettings/DashboardSettings';
import { formatDate } from 'utilities/formatDate';

import { formatGestationalAges } from '../utils/format-gestational-age';
import { ColumnContainer, Header, StyledInfoAreaContainer, TinyText } from './LMPEntered.styled';
import { PregnancyCalculatorActionButtons } from './PregnancyCalculatorActionButtons';
import { TileWidget } from './TileWidget';

interface Props {
  onCancelClick?: () => void;
  gestationalAgeInDays: number;
  estimatedDueDate: Date;
}

export const LMPEntered = ({ onCancelClick, gestationalAgeInDays, estimatedDueDate }: Props) => (
  <TileWidget state="lmp-entered">
    <DashboardSettings />

    <Header>
      <Text fontStyle="regular" size="lg">
        You are now...
      </Text>
      <Heading tag="div" styledAs="h2" noMargin>
        {formatGestationalAges(gestationalAgeInDays)}
      </Heading>
      <Text fontStyle="regular" size="lg">
        into your pregnancy!
      </Text>
    </Header>

    <StyledInfoAreaContainer>
      <ColumnContainer>
        <Text fontStyle="regular" size="xs">
          Estimated Due Date
        </Text>
        <Heading tag="div" styledAs="h2" noMargin>
          {formatDate(estimatedDueDate, 'MMM D')}
        </Heading>
      </ColumnContainer>
    </StyledInfoAreaContainer>
    <TinyText>These dates will change.</TinyText>
    <PregnancyCalculatorActionButtons onCancelClick={onCancelClick} />
  </TileWidget>
);
