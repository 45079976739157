import React from 'react';

import { CustomWidget } from '../CustomWidget/CustomWidget';
import { DashboardWidgetList } from '../DashboardSettings/DashboardSettings.types';
import { getCustomTileId, isHiddenWidget } from '../DashboardSettings/DashboardSettings.utils';
import { useCustomDashboardTiles } from './CustomWidgets.hooks';
import { CustomTile } from './CustomWidgets.types';
import { showCustomTile } from './CustomWidgets.utils';

interface Props {
  hiddenWidgets?: DashboardWidgetList;
}

export const CustomWidgets = ({ hiddenWidgets }: Props) => {
  const { data, loading } = useCustomDashboardTiles();
  const customDashboardTiles = data?.customDashboardTiles;
  const dataAvailable = !loading && customDashboardTiles?.length;

  if (!dataAvailable || !customDashboardTiles) return null;

  return <>{getCustomTiles(customDashboardTiles, hiddenWidgets)}</>;
};

const getCustomTiles = (customTiles: CustomTile[], hiddenWidgets?: DashboardWidgetList) => {
  return customTiles.filter(showCustomTile).map((tile, index) => {
    const customTileId = getCustomTileId(tile);

    if (isHiddenWidget(hiddenWidgets, customTileId)) return null;

    return (
      <React.Fragment key={customTileId}>
        <CustomWidget customTile={tile} backgroundVariant={index % 4} />
      </React.Fragment>
    );
  });
};
