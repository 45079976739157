import { gql, useQuery, useMutation } from '@apollo/client';

import { DashboardWidgetList } from './DashboardSettings.types';

interface HiddenDashboardWidgetsResponse {
  patientUiSettings: {
    hiddenDashboardWidgets: DashboardWidgetList;
  };
}

const HIDDEN_DASHBOARD_WIDGETS = gql`
  query patientUiSettings {
    patientUiSettings {
      hiddenDashboardWidgets
    }
  }
`;

export const useHiddenDashboardWidgets = () => {
  const { data, loading } = useQuery<HiddenDashboardWidgetsResponse>(HIDDEN_DASHBOARD_WIDGETS);

  return { data: data?.patientUiSettings.hiddenDashboardWidgets, loading };
};

const UPDATE_HIDDEN_DASHBOARD_WIDGETS = gql`
  mutation updateHiddenDashboardWidgets($hiddenDashboardWidgets: JSON!) {
    updateHiddenDashboardWidgets(hiddenDashboardWidgets: $hiddenDashboardWidgets) {
      patientUiSettings {
        hiddenDashboardWidgets
      }
    }
  }
`;

export const useUpdateHiddenDashboardWidgets = () => {
  const [updateHiddenDashboardWidgets, { loading }] = useMutation(UPDATE_HIDDEN_DASHBOARD_WIDGETS, {
    refetchQueries: [HIDDEN_DASHBOARD_WIDGETS]
  });

  return {
    updateHiddenDashboardWidgets,
    loading
  };
};
