import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import { DashboardSettings } from 'screens/Dashboard/DashboardSettings/DashboardSettings';

import { InfoAreaContainer, TextContainer } from './styled-components';
import { TileWidget } from './TileWidget';
interface Props {
  onPregnantClick: () => void;
}

export const NoConfirmedPregnancy: React.FC<Props> = ({ onPregnantClick }) => (
  <TileWidget state="no-confirmed-pregnancy">
    <DashboardSettings />

    <TextContainer>
      <Text color={themes.colors.neutral.primaryNavy} fontStyle="medium" size="lg">
        Once you are pregnant you can track your weeks of pregnancy & due date here.
      </Text>
    </TextContainer>
    <InfoAreaContainer>
      <Text color={themes.colors.neutral.primaryNavy} fontStyle="medium" size="lg">
        If you have a regular menstrual cycle and are late by <strong>more than 7 days,</strong>{' '}
        please take a pregnancy test and let us know if you're pregnant!
      </Text>
      <Button
        category="primary"
        size="lg"
        fullWidth={true}
        label="I am Pregnant!"
        onClick={onPregnantClick}
      />
    </InfoAreaContainer>
  </TileWidget>
);
