import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import {
  CalendarCheck,
  ClipBoardMedical,
  ClipboardUser,
  Eye,
  EyeSlash,
  HeartPulse,
  Route,
  EmployerBenefits,
  Seedling,
  Droplet,
  CalendarHeart,
  Link
} from 'assets/icons/Icons';
import { Loader } from 'components/Loader/Loader';
import { Modal } from 'components/v2/Modal/Modal';
import { Patient } from 'kb-shared';

import { CustomTile } from '../CustomWidgets/CustomWidgets.types';
import { JourneyResponse } from '../Dashboard.types';
import {
  showActivateBenefitsWidget,
  showBenefitsWidget,
  showEmbryoIncWidget,
  showFertilityCalculator,
  showJourneyWidget,
  showPeriodTracker,
  showPregnancyCalculatorWidget,
  showSaicWidget
} from '../Widgets/Widgets.utils';
import { DashboardWidgetId, DashboardWidgetList } from './DashboardSettings.types';
import {
  isHiddenWidget,
  compareHiddenDashboardWidgetSettings,
  getCustomTileId
} from './DashboardSettings.utils';
import {
  WidgetList,
  WidgetButton,
  WidgetTitle,
  WidgetId,
  InstructionText,
  ActionButton,
  ActionsContainer,
  LockIcon
} from './SettingsModal.styled';

interface Props {
  hiddenWidgets: DashboardWidgetList;
  showLoader: boolean;
  onClose: VoidFunction;
  onSave: (widgetsToHide: DashboardWidgetList) => void;
  patient: Patient;
  journey?: JourneyResponse;
  customTiles?: Array<CustomTile>;
}

export const SettingsModal = ({
  hiddenWidgets,
  showLoader,
  onClose,
  onSave,
  patient,
  journey,
  customTiles
}: Props) => {
  const [selectedWidgets, setSelectedWidgets] = useState<DashboardWidgetList>(hiddenWidgets);
  const handleWidgetToggle = (widgetId: DashboardWidgetId, hide: boolean) => {
    const widgetsToHide = hide
      ? Array.from(new Set([...selectedWidgets, widgetId]))
      : selectedWidgets.filter(id => id !== widgetId);

    setSelectedWidgets(widgetsToHide);
  };
  const hiddenWidgetsIdentical = compareHiddenDashboardWidgetSettings(
    hiddenWidgets,
    selectedWidgets
  );
  const widgetItems = useMemo(
    () => widgets.filter(item => item.displayOptionFilter({ patient, journey })),
    [patient, journey]
  );

  // update selected widgets when parent component updates hidden widgets
  useEffect(() => {
    setSelectedWidgets(hiddenWidgets);
  }, [hiddenWidgets]);

  return (
    <Modal open title="Edit Dashboard" onCancel={onClose} maxWidth={800}>
      <InstructionText tag="p" fontStyle="semibold" size="sm">
        Select which widgets you want to show on your dashboard
      </InstructionText>

      <WidgetList>
        {widgetItems.map(item => {
          let widgetToggleIcon: ReactNode = null;
          const visible = !isHiddenWidget(selectedWidgets, item.id);

          if (item.locked) {
            widgetToggleIcon = <LockIcon />;
          } else {
            if (!visible) {
              widgetToggleIcon = <EyeSlash type="solid" />;
            } else {
              widgetToggleIcon = <Eye type="solid" />;
            }
          }

          return (
            <WidgetButton
              key={item.title}
              onClick={() => {
                handleWidgetToggle(item.id, visible);
              }}
              disabled={item.locked}
              $shownToUser={visible}
            >
              <WidgetId>
                {item.icon}
                <WidgetTitle>{item.title}</WidgetTitle>
              </WidgetId>
              {widgetToggleIcon}
            </WidgetButton>
          );
        })}
        {customTiles?.map(customTile => {
          const customTileId = getCustomTileId(customTile);
          let widgetToggleIcon: ReactNode = null;
          const visible = !isHiddenWidget(selectedWidgets, customTileId);

          if (!visible) {
            widgetToggleIcon = <EyeSlash type="solid" />;
          } else {
            widgetToggleIcon = <Eye type="solid" />;
          }

          return (
            <WidgetButton
              key={customTile.id}
              onClick={() => handleWidgetToggle(customTileId, visible)}
              $shownToUser={visible}
            >
              <WidgetId>
                <Link {...iconProps} />
                <WidgetTitle>{customTile.header}</WidgetTitle>
              </WidgetId>
              {widgetToggleIcon}
            </WidgetButton>
          );
        })}
      </WidgetList>

      {showLoader && <Loader absoluteCentered container />}

      <ActionsContainer>
        <ActionButton type="button" category="secondary" label="Cancel" onClick={onClose} />
        <ActionButton
          type="button"
          category="primary"
          label="Save Changes"
          onClick={() => onSave(selectedWidgets)}
          isDisabled={hiddenWidgetsIdentical || showLoader}
        />
      </ActionsContainer>
    </Modal>
  );
};

const iconProps = {
  width: '16px',
  height: '16px'
} as const;

const widgets: Array<{
  title: string;
  id: DashboardWidgetId;
  locked: boolean;
  icon: ReactNode;
  displayOptionFilter: (args: { patient: Patient; journey?: JourneyResponse }) => boolean;
}> = [
  {
    title: 'Appointments',
    id: 'appointments',
    locked: true,
    icon: <CalendarCheck {...iconProps} />,
    displayOptionFilter: () => true
  },
  {
    title: 'Employer Benefits',
    id: 'employerBenefits',
    locked: true,
    icon: <EmployerBenefits type="solid" {...iconProps} />,
    displayOptionFilter: ({ patient }) => showBenefitsWidget(patient)
  },
  {
    title: 'Activate Benefits',
    id: 'activateBenefits',
    locked: true,
    icon: <EmployerBenefits type="solid" {...iconProps} />,
    displayOptionFilter: ({ patient }) => showActivateBenefitsWidget(patient)
  },
  {
    title: 'Support Groups',
    id: 'supportGroups',
    locked: false,
    icon: <HeartPulse type="solid" {...iconProps} />,
    displayOptionFilter: () => true
  },
  {
    title: 'My Journey',
    id: 'myJourney',
    locked: false,
    icon: <Route type="solid" {...iconProps} />,
    displayOptionFilter: ({ journey }) => showJourneyWidget(journey)
  },
  {
    title: 'My Results',
    id: 'myResults',
    locked: false,
    icon: <ClipBoardMedical {...iconProps} />,
    displayOptionFilter: () => true
  },
  {
    title: 'My Profile',
    id: 'myProfile',
    locked: false,
    icon: <ClipboardUser type="solid" {...iconProps} />,
    displayOptionFilter: () => true
  },
  {
    title: 'Fertility Calculator',
    id: 'fertilityCalculator',
    locked: false,
    icon: <Seedling type="solid" {...iconProps} />,
    displayOptionFilter: ({ patient }) => showFertilityCalculator(patient)
  },
  {
    title: 'Period Tracker',
    id: 'periodTracker',
    locked: false,
    icon: <Droplet type="solid" {...iconProps} />,
    displayOptionFilter: ({ patient }) => showPeriodTracker(patient)
  },
  {
    title: 'Pregnancy Calculator',
    id: 'pregnancyCalculator',
    locked: false,
    icon: <CalendarHeart type="solid" {...iconProps} />,
    displayOptionFilter: ({ patient }) => showPregnancyCalculatorWidget(patient)
  },
  {
    title: 'SAIC',
    id: 'saic',
    locked: false,
    icon: <CalendarCheck {...iconProps} />,
    displayOptionFilter: ({ patient }) => showSaicWidget(patient)
  },
  {
    title: 'Embryo Inc',
    id: 'embryoInc',
    locked: false,
    icon: <CalendarCheck {...iconProps} />,
    displayOptionFilter: ({ patient }) => showEmbryoIncWidget(patient)
  }
] as const;
